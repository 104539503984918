import React from "react";
import { Grid } from "@material-ui/core";
import { Inquiry } from "../components/Inquiry";
import { isMobile } from "react-device-detect";

import {
  FooterContainer,
  FooterText,
  SnapFooterLogo,
  AllRightsReservedContainer,
  DevByText,
  FooterTextAllRight
} from "./styledComponents";

import SNAPLogo from "../assets/logo/snap.svg";

export const Footer = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <FooterContainer>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <AllRightsReservedContainer>
              <FooterTextAllRight>
                © SCULPTURE BANGKOK {new Date().getFullYear()}. ALL RIGHT
                RESERVED.
              </FooterTextAllRight>
              <div>
                <DevByText>
                  SITE DESIGNED BY PLOY PHAIRAM D. | DEVELOPMENT BY MIX CHANAWEE
                </DevByText>
                {/* <FooterText>DEVELOPMENT BY MIX CHANAWEE</FooterText> */}
              </div>
            </AllRightsReservedContainer>
          </Grid>
          {!isMobile && (
            <Grid item sm={12} md={4} lg={7}>
              <Grid container spacing={2}>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <a href={"/about"}>
                      <FooterText>About us</FooterText>
                    </a>
                  </FooterText>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <a href={"/store"}>
                      <FooterText>Store</FooterText>
                    </a>
                  </FooterText>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <a href={"https://www.instagram.com/sculpturebangkok/"}>
                      <FooterText>Instagram</FooterText>
                    </a>
                  </FooterText>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <a href={"/contact"}>
                      <FooterText>Contact us</FooterText>
                    </a>
                  </FooterText>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <a href={"https://snapphotoservice.com/"}>
                      <SnapFooterLogo src={SNAPLogo} />
                    </a>
                  </FooterText>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <a
                      href={
                        "https://www.facebook.com/Sculpturebangkok-106077208099767"
                      }
                    >
                      <FooterText>Facebook</FooterText>
                    </a>
                  </FooterText>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <FooterText onClick={() => openModal(true)}>
                      Collaborate with us
                    </FooterText>
                  </FooterText>
                </Grid>
                <Grid item sm={6} md={4} lg={4}>
                  <FooterText>
                    <a href={"/wedding"}>
                      <FooterText>Sculpture wedding</FooterText>
                    </a>
                  </FooterText>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Inquiry isOpen={isOpen} closeModal={closeModal} />
      </FooterContainer>
    </>
  );
};
