import styled, { keyframes } from "styled-components";
import { animated } from "react-spring";

export const moveAnimationOne = keyframes`
 0% { transform: translate(0px, 0px) rotate(0deg) }
 20% { transform: translate(10px, -10px) rotate(2deg) }
 40% { transform: translate(2px, -20px) rotate(4deg) }
 60% { transform: translate(-8px, -13px) rotate(1deg) }
 80% { transform: translate(-3px, -6px) rotate(-2deg) }
 100% { transform: translate(0px, 0px) rotate(0deg) }
`;

export const moveAnimationTwo = keyframes`
 0% { transform: translate(10px, -10px) rotate(2deg) }
 20% { transform: translate(2px, -20px) rotate(4deg) }
 40% { transform: translate(-8px, -13px) rotate(1deg) }
 60% { transform: translate(-3px, -6px) rotate(-2deg) }
 80% { transform: translate(0px, 0px) rotate(0deg) }
 100% { transform: translate(0px, 0px) rotate(0deg) }
`;

export const moveAnimationThree = keyframes`
 0% { transform: translate(2px, -20px) rotate(4deg) }
 20% { transform: translate(-8px, -13px) rotate(1deg) }
 40% { transform: translate(-3px, -6px) rotate(-2deg) }
 60% { transform: translate(0px, 0px) rotate(0deg) }
 80% { transform: translate(0px, 0px) rotate(0deg) }
 100% { transform: translate(10px, -10px) rotate(2deg) }
`;

export const moveAnimationFour = keyframes`
 0% { transform: translate(-8px, -13px) rotate(1deg) }
 20% { transform: translate(-3px, -6px) rotate(-2deg) }
 40% { transform: translate(0px, 0px) rotate(0deg) }
 60% { transform: translate(0px, 0px) rotate(0deg) }
 80% { transform: translate(10px, -10px) rotate(2deg) }
 100% { transform: translate(2px, -20px) rotate(4deg) }
`;

export const moveAnimationFive = keyframes`
 0% { transform: translate(-3px, -6px) rotate(-2deg) }
 20% { transform: translate(0px, 0px) rotate(0deg) }
 40% { transform: translate(0px, 0px) rotate(0deg) }
 60% { transform: translate(10px, -10px) rotate(2deg) }
 80% { transform: translate(2px, -20px) rotate(4deg) }
 100% { transform: translate(-8px, -13px) rotate(1deg) }
`;

export const moveAnimationSix = keyframes`
 0% { transform: translate(0px, 0px) }
 20% { transform: translate(30px, -30px) }
 40% { transform: translate(-20px, -40px) }
 60% { transform: translate(25px, -10px) }
 80% { transform: translate(-30px, -25px)  }
 100% { transform: translate(0px, 0px) }
`;

export const Ul = styled.ul`
  display: flex;
  margin: 0 0 0 12px;
  padding: 0;
  list-style: none;
  @media (max-width: 850px) {
    /* display: none; */
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: -40px 0 0 0;
  }
  @media (max-width: 480px) {
    /* display: none; */
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: -40px 0 0 0;
  }
`;

export const Li = styled.li`
  font-size: 20px;
  margin: 0px 40px 0px 0px !important;
  display: flex;
  align-items: center;
  @media (max-width: 850px) {
    font-size: 24px;
    margin: 0px 20px !important;
  }
`;

export const LogoContainer = styled.div`
  width: 190px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 850px) {
    position: absolute;
    left: -30px;
    flex-shrink: 0;
    display: block;
    width: 160px;
    height: 55px;
    display: flex;
  }
`;

export const LogoImage = styled.img`
  height: 28px;
  margin: 0px;
  margin-left: 75px;
  @media (max-width: 850px) {
    margin: 0px;
    height: 20px;
  }
`;

export const BoxContainer = styled.div`
  position: absolute;
  animation-name: ${props => props.animation};
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: ${props => (props.visible ? "block" : "none")};
  /* :hover {
    transform: scale(1.02);
    transition-duration: 100ms;
  } */
`;

export const BoxContainerDesktop = styled.div`
  position: absolute;
  animation-name: ${props => props.animation};
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  z-index: -1;
  display: block;
`;

export const Box = styled.div`
  cursor: move;
  color: black;
  width: ${props => props.width}px;
  height: ${props => props.width}px;
  margin: auto;
  user-select: none;
  background: url(${props => props.image}) no-repeat center;
  background-size: 100% auto;
  :hover {
    transform: scale(1.02);
    transition-duration: 100ms;
  }
`;

export const Item = styled.div`
  display: none;
  width: auto;
  height: auto;
  @media (max-width: 850px) {
    display: block;
  }
`;

export const LocationBooth = styled.div`
  position: absolute;
  cursor: move;
  color: black;
  width: ${props => props.width}px;
  height: 200px;
  border-radius: 5px;
  margin: auto;
  user-select: none;
  background: url(${props => props.image}) no-repeat center;
  background-size: 100% auto;
  transform: rotate(${props => props.rotate}deg);
  z-index: -1;
`;

export const TapToStartBg = styled.div`
  width: 90%;
  height: 146px;
  display: flex;
  flex-direction: column;
  bottom: 20px;
  padding: 5px;
  justify-content: center;
  align-items: center;
`;

export const TapToStartHeader = styled.p`
  color: black;
  margin: 0px;
  font-size: 24px;
  font-weight: 300;
  font-family: "Futura", sans-serif;
  user-select: none;

  @media (max-width: 850px) {
    font-size: 16px;
  }
`;

export const TapToStartContainer = styled.div`
  position: absolute;
  display: ${props => (props.visible ? "flex" : "none")};
  justify-content: center;
  width: 320px;
  left: 160px;
  bottom: 50px;
  @media (max-width: 850px) {
    position: absolute;
    display: ${props => (props.visible ? "flex" : "none")};
    justify-content: center;
    width: 100%;
    bottom: 20px;
    left: 0px;
  }
`;

export const TapToStartImage = styled.img`
  width: 80%;
  cursor: pointer;
  @media (max-width: 850px) {
    width: 50%;
    margin: 5px;
  }
`;

export const PhotoboothBody = styled.p`
  color: white;
  font-size: 12px;
  margin: 0px;
  text-align: center;
`;

export const LandingContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 53px);
`;

export const BannerTopLeft = styled.div`
  position: fixed;
  left: 0px;
  width: 530px;
  height: 170px;
  margin-top: 0px;
  @media only screen and (max-width: 480px) {
    width: 450px;
    height: 150px;
  }
`;

export const BannerTopLeftImage = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const Container = styled.div`
  width: 100%;
  @media only screen and (max-width: 480px) {
    /* height: calc(100vh - 100px); */
    height: ${props => (props.isAboutMobile ? "calc(100vh - 50px)" : "auto")};
  }
`;

export const FooterContainer = styled.div`
  width: 100%;
  padding: 42px 45px;
  background-color: black;
  position: relative;
  z-index: 9999;
  bottom: 0;
  @media only screen and (max-width: 480px) {
    padding: 16px 19px;
  }
`;

export const FooterText = styled.span`
  font-size: 20px;
  font-weight: 300;
  margin: 0px 0px;
  color: white;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
`;

export const FooterTextAllRight = styled.span`
  font-size: 20px;
  font-weight: 300;
  margin: 0px 0px;
  color: white;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
`;

export const DevByText = styled.p`
  font-size: 16px;
  font-weight: 300;
  margin: 0px 0px;
  color: white;
  cursor: pointer;
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

export const SnapFooterLogo = styled.img`
  height: 25px;
  cursor: pointer;
`;

export const DropdownBox = styled(animated.div)`
  position: absolute;
  bottom: -50px;
  right: 0px;
  width: 240px;
  height: 56px;
  display: ${props => (props.showservice ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 0.5px solid black;
  padding: 15px 45px 15px 45px;
  font-size: 16px;
  color: black;
  @media only screen and (max-width: 480px) {
    position: relative;
    width: 118px;
    right: 0px;
    top: 5px;
    height: 45px;
    padding: 0px;
    font-size: 20px !important;
    background-color: transparent !important;
  }
`;

export const DropdownText = styled.span`
  margin: 0px;
  margin-left: 0px;
  color: black;
  text-transform: uppercase;
  flex-wrap: none;
  :hover {
    text-decoration: underline;
  }
  @media only screen and (max-width: 480px) {
    text-transform: none;
    font-size: 20px;
    color: white !important;
  }
`;

export const DropdownImage = styled.img`
  position: absolute;
  right: 5px;
  top: 37px;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

export const DropdownImageMobile = styled.img`
  display: none;

  @media only screen and (max-width: 850px) {
    display: block;
    position: absolute;
    right: 0px;
    top: 30px;
  }
`;

export const SiteWrapper = styled.div`
  background-color: ${props =>
    props.bgColor ? props.bgColor : "#FCF9ED"} !important;
  min-height: 100vh !important;
`;

export const Bg = styled.div`
  background-color: #fcf9ed;
  width: 100%;
  height: 100%;
`;

export const Nav = styled.nav`
  position: ${props => (props.isIndex ? "absolute" : "relative")};
  right: ${props => props.isIndex && "5px"};
  top: ${props => props.isIndex && "5px"};
  @media only screen and (max-width: 850px) {
    display: ${props => !props.toggleNav && "none"};
    position: ${props => (props.isIndex ? "absolute" : "relative")};
    margin-top: ${props =>
      props.toggleNav ? (props.isIndex ? "-30px" : "0px") : "0px"};
    height: 100vh !important;
    background-color: ${props =>
      props.toggleNav ? "black" : props.disable ? "transparent" : "white"};
    align-items: flex-start;
    padding-top: 110px;
    right: 0px;
    top: 0px;
  }
  @media only screen and (max-width: 480px) {
    display: ${props => !props.toggleNav && "none"};
    margin-top: ${props =>
      props.toggleNav ? (props.isIndex ? "0px" : "-60px") : "0px"};
    height: 100vh !important;
    background-color: ${props =>
      props.toggleNav ? "black" : props.disable ? "transparent" : "white"};
    align-items: flex-start;
    padding-top: 110px;
    right: 0px;
    top: 0px;
  }
`;

export const AllRightsReservedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  @media only screen and (max-width: 480px) {
    width: 100%;
    justify-content: center;
  }
`;

export const BoxText = styled.p`
  position: absolute;
  width: 100%;
  bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-family: "Futura";
  color: ${props => (props.color ? props.color : "white")};
  cursor: pointer;
  :hover {
    transform: scale(1.07);
    transition-duration: 100ms;
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
`;

export const Canvas = styled.canvas`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

export const isMobile = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: block;
    width: 100vw;
    height: 100vh;
  }
`;

export const isDesktop = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;
