import React from "react";
import styled from "styled-components";
import RSCLogo from "../../assets/RandomSculptureClubPage/randomsculptureclublogo.png";

export const StoreWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 100px;
  left: 0px;
  /* overflow: scroll; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 0px;
  @media only screen and (max-width: 840px) {
    padding-top: 8px;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 0px;
    flex-direction: column;
    overflow-x: hidden;
  }
`;

export const StoreLogo = styled.img`
  width: 183px;
  height: auto;
  @media only screen and (max-width: 480px) {
    width: 108px;
    margin-top: 8px;
  }
`;

export const Ul = styled.ul`
  list-style-type: none;
  margin-top: 62px;
  padding-left: 0px;
  @media only screen and (max-width: 850px) {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    justify-content: space-around;
    padding: 5px 10px;
    width: fit-content;
    margin-bottom: 0px;
    width: 100%;
    border-top: 0.5px solid black;
    border-bottom: 0.5px solid black;
  }
  @media only screen and (max-width: 480px) {
    position: absolute;
    bottom: 0px;
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    justify-content: space-around;
    padding: 5px 10px;
    width: fit-content;
    margin-bottom: 0px;
    width: 100%;
    border-top: 0.5px solid black;
    border-bottom: 0px;
  }
`;

export const Li = styled.li`
  cursor: pointer;
  font-family: "Futura Condensed";
  font-size: 20px;
  color: black;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 16px;
  margin: 0px 0px 22px 0px;
  @media only screen and (max-width: 850px) {
    font-size: 20px;
    margin: 10px 5px 10px 5px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 16px;
    margin: 10px 5px 10px 5px;
  }
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  border: 0.5px solid black;
  margin-right: 16px;
  background-color: ${props => (props.selectedSection ? "black" : "white")};
  cursor: pointer;
`;

export const HeaderBox = styled.div`
  width: 317px;
  position: fixed;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;
  border-right: 0.5px solid #000000;
  height: calc(100% - 53px);
  @media only screen and (max-width: 850px) {
    width: 100%;
    border: 0px;
    /* padding-top: 54px; */
    background-color: white;
    z-index: 99999;
    height: fit-content;
    padding-top: 0px;
  }
  @media only screen and (max-width: 480px) {
    display: none;
    width: 100%;
    border: 0px;
    padding-top: 0px;
    background-color: white;
    z-index: 99999;
    height: fit-content;
  }
`;

export const HeaderBoxMobile = styled.div`
  display: none;
  width: 317px;
  position: fixed;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;
  border-right: 0.5px solid #000000;
  height: calc(100vh - 53px);
  @media only screen and (max-width: 850px) {
    width: 100%;
    border: 0px;
    padding-top: 0px;
    background-color: white;
    z-index: 99999;
    height: fit-content;
  }
  @media only screen and (max-width: 480px) {
    position: relative;
    display: flex;
    width: 100%;
    border: 0px;
    padding-top: 0px;
    background-color: white;
    z-index: 99999;
    margin-top: 1px;
    height: 140px;
    border-top: 0.5px solid black;
  }
`;

export const StoreContainer = styled.div`
  position: relative;
  width: calc(100% - 317px);
  margin-left: 317px;
  @media only screen and (max-width: 850px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 182px;
    z-index: 99998;
  }
  @media only screen and (max-width: 480px) {
    width: 100%;
    margin: 0px 0px 0px 0px;
    z-index: 99998;
  }
`;

export const ItemContainer = styled.div`
  width: 100%;
  height: fit-content;
  border-right: 0.5px solid black;
  border-bottom: 0.5px solid black;
  padding: 16px;
  @media only screen and (max-width: 850px) {
  }
  @media only screen and (max-width: 480px) {
    border-right: 0px;
    width: 100vw;
  }
`;

export const ItemImageContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 125%;
`;

export const ItemImage = styled.img`
  position: absolute;
  top: 0px;
  height: 100%;
  /* height: 625px; */
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  @media only screen and (max-width: 1500px) {
    height: 100%;
    /* height: 400px; */
  }
  @media only screen and (max-width: 480px) {
    height: 100%;
    /* height: 500px; */
  }
`;

export const ItemDetailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 20px;
  margin-top: 10px;
  overflow: hidden;
  height: 22px;
`;

export const ItemPrice = styled.span`
  font-size: 20px;
`;

export const ItemTitle = styled.span`
  font-size: 20px;
  cursor: pointer;
`;

export const ItemDesc = styled.span`
  font-size: 16px;
  width: 100%;
  cursor: pointer;
`;

export const FooterContainer = styled.div`
  height: fit-content;
  width: 100vw;
  margin-left: -317px;
  @media only screen and (max-width: 850px) {
    margin-left: 0px;
  }
`;

export const StoreSection = styled.section`
  position: absolute;
  top: -53px;
  @media only screen and (max-width: 850px) {
  }
  @media only screen and (max-width: 480px) {
    top: -120px !important;
  }
`;

export const StoreHeader = () => {
  return (
    <HeaderBox>
      <StoreLogo src={RSCLogo} />
      <Ul>
        <Li>
          <Dot selectedSection={"allitems"} />
          ALL ITEMS
        </Li>
      </Ul>
    </HeaderBox>
  );
};

export const StoreHeaderMobile = () => {
  return (
    <HeaderBoxMobile>
      <StoreLogo src={RSCLogo} />
      <Ul>
        <Li>
          <Dot selectedSection={"allitems"} />
          ALL ITEMS
        </Li>
      </Ul>
    </HeaderBoxMobile>
  );
};

export const HandWritten = styled.img`
  width: 100%;
`;
