/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            hrefLang
            author {
              name
              url
              email
            }
          }
        }
      }
    `
  );

  const metaDescription = site.siteMetadata.description;

  return (
    <Helmet title={title} defaultTitle={title} titleTemplate={title}>
      <html lang={site.siteMetadata.hrefLang} />
      <meta name="description" content={metaDescription} />
      <meta
        name="image"
        content={
          "https://firebasestorage.googleapis.com/v0/b/sculpture-bangkok-website.appspot.com/o/website%2Flogo%2FLogoSquare.png?alt=media&token=044c61ed-613f-49b4-a516-fd804298c193"
        }
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={site.siteMetadata.siteUrl} />
      <meta property="og:description" content={metaDescription} />
      <meta
        property="og:image"
        content={
          "https://firebasestorage.googleapis.com/v0/b/sculpture-bangkok-website.appspot.com/o/website%2Flogo%2FLogoSquare.png?alt=media&token=044c61ed-613f-49b4-a516-fd804298c193"
        }
      />
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={site.siteMetadata.siteUrl} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:image"
        content={
          "https://firebasestorage.googleapis.com/v0/b/sculpture-bangkok-website.appspot.com/o/website%2Flogo%2FLogoSquare.png?alt=media&token=044c61ed-613f-49b4-a516-fd804298c193"
        }
      />
      <meta name="twitter:creator" content={site.siteMetadata.author.name} />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
  description: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired
};

export default SEO;
