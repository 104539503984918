import styled from "styled-components";

export const PageName = styled.h1`
  display: none;
  @media only screen and (max-width: 480px) {
    font-size: 20px;
    display: block;
    color: black;
    margin: 13.5px 0px;
    font-weight: 300;
  }
`;

export const SiteHead = styled.header`
  width: 100vw;
  position: fixed;
  z-index: 999999;
  font-size: 1.35rem;
  background-color: ${props => (props.disable ? "transparent" : "white")};
  border-bottom: ${props => (props.disable ? "0px" : "0.5px solid black")};
`;

export const StyledLink = styled.a`
  position: relative;
  text-transform: uppercase;
  font-family: Futura Condensed;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  @media only screen and (max-width: 850px) {
    color: white !important;
    font-size: 24px;
    margin: 0px;
    margin-top: 0px !important;
    margin-bottom: 24px !important;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NavBurger = styled.a`
  top: ${props => (props.isIndex ? "28px" : "8px")};
  right: ${props => (props.isIndex ? "5px" : "3px")};
  display: block;
  @media only screen and (max-width: 850px) {
    right: 3px;
    display: block;
  }
  @media only screen and (max-width: 480px) {
    top: 7px;
  }
`;

export const SnapFooterLogo = styled.img`
  height: 25px;
  cursor: pointer;
`;

export const Main = styled.main`
  height: 100%;
`;
export const NavbarIconContainer = styled.div`
  width: 120px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 850px) {
    width: 120px;
    height: 60px;
  }
  @media only screen and (max-width: 480px) {
    width: 120px;
    height: 60px;
  }
`;

export const NavbarIconImage = styled.img`
  width: 105px;
  height: 25px;
  cursor: pointer;
  :hover {
    width: 120px;
    height: 27px;
    transition-duration: 100ms;
  }
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

export const NavbarIconImageMobile = styled.img`
  display: none;
  cursor: pointer;
  :hover {
    width: 120px;
    height: 27px;
    transition-duration: 100ms;
  }
  @media only screen and (max-width: 850px) {
    width: 105px;
    height: 25px;
    display: block;
  }
  @media only screen and (max-width: 480px) {
    width: 105px;
    height: 25px;
  }
`;

export const BottomNavSpacer = styled.div`
  width: calc(100% - 16px);
  height: 0.5px;
  margin: 0px 0px 30px 0px;
  background: white;
`;

export const BottomNavContainer = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SocialMediaWrapper = styled.div`
  margin: 5px 17px;
`;

export const FooterContainerBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const AllrightReserveText = styled.div`
  font-weight: 300;
  font-size: 16px;
  margin: 0px 0px 0px 0px;
  color: white;
  text-align: center;
`;

export const DesignedBy = styled.p`
  margin: 0px;
  color: white;
  text-align: center;
  font-size: 12px;
`;

export const IconImage = styled.img`
  height: 32px;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
  :hover {
    height: 34px;
    transition-duration: 100ms;
  }
  @media only screen and (max-width: 840px) {
    margin-bottom: 24px;
  }
  @media only screen and (max-width: 480px) {
    height: 27px;
    margin-bottom: 24px;
  }
`;
