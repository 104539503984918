import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Label = styled.p`
  font-size: 24px;
  width: 100%;
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
`;

const Desc = styled.p`
  font-size: 20px;
  width: 100%;
  margin: 0px;
  margin-bottom: 35px;
  text-align: center;
`;

const SendButton = styled.div`
  width: 100%;
  height: 40px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.25);
  }
`;

const SendButtonText = styled.p`
  font-size: 20px;
  color: white;
  text-align: center;
  margin: 0px;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px",
    border: "0.5px solid #000000",
    padding: "56px 56px 24px 56px",
    width: "455px",
    height: "auto"
  },
  overlay: {
    backgroundColor: "#00000080",
    zIndex: "9999"
  }
};

export const FailedToSendRequest = ({ isOpen, closeModal }) => {
  return (
    <>
      <div>
        <Modal
          isOpen={isOpen}
          onRequestClose={() => closeModal("clear")}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <GrClose
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              cursor: "pointer"
            }}
            size={16}
            onClick={() => closeModal("clear")}
          />
          <Container>
            <Label>Failed to send,</Label>
            <Desc>
              please contant Random Sculpture directly via line or email.
            </Desc>
            <Desc>Line: @sculpturebangkok</Desc>
            <Desc>Email: thisissculpture@gmail.com</Desc>
            <SendButton>
              <SendButtonText onClick={() => closeModal("clear")}>
                Back to home
              </SendButtonText>
            </SendButton>
          </Container>
        </Modal>
      </div>
    </>
  );
};
