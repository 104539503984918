import React, { useState } from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { navigate } from "gatsby";

const BgScreen = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  display: ${props => (props.toggleNav ? "block" : "none")};
  width: 100vw;
  height: 100vh;
  background: transparent;
  cursor: pointer;
`;

const AnimatedNav = styled(animated.nav)`
  position: absolute;
  width: 507px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 160px 45px 45px 45px;
  background-color: black;
  top: 0px;
  right: -507px;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 9999;
  @media only screen and (max-width: 840px) {
    display: none;
  }
`;

const Ul = styled.ul`
  width: 100%;
  height: fit-content;
`;

const Li = styled.li`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-bottom: 30px;
  height: fit-content;
`;

const WhiteLine = styled.div`
  width: auto;
  height: 0.5px;
  background-color: white;
  margin-bottom: 30px;
`;

const IconImage = styled.img`
  height: 32px;
  max-width: 100%;
  object-fit: contain;
  cursor: pointer;
  :hover {
    height: 34px;
    transition-duration: 100ms;
  }
`;

const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const SocialIcon = styled.img`
  height: 43px;
  width: auto;
  margin: 10px 30px;
  cursor: pointer;
`;

const DropdownImageMobile = styled.img`
  position: absolute;
  top: 10px;
  right: calc(50% - 80px) !important;
  display: block;
  width: 12px;
  margin-top: 5px;
`;

const DropdownText = styled.span`
  width: 100%;
  text-align: center;
  margin-top: 17px;
  color: white;
  text-transform: uppercase;
  flex-wrap: none;
  cursor: pointer;
  font-size: 20px;
  :hover {
    text-decoration: underline;
  }
`;

export const DesktopNavBurger = ({
  toggleNav,
  setToggleNav,
  locationIcon,
  storeIcon,
  serviceIcon,
  aboutUsIcon,
  contactUsIcon,
  collabWithUsIcon,
  snapIcon,
  Facebook,
  Instagram,
  navDropdownWhite,
  setShowCollab
}) => {
  const springStyles = useSpring({
    right: toggleNav ? 0 : -507
  });

  const [showservice, setShowservice] = useState(false);

  return (
    <>
      <AnimatedNav style={springStyles}>
        <BgScreen
          toggleNav={toggleNav}
          onClick={() => setToggleNav(!toggleNav)}
        />
        <Ul>
          <Li>
            <IconImage
              src={locationIcon}
              onClick={() => navigate("/locations")}
            />
          </Li>
          <Li>
            <IconImage src={storeIcon} onClick={() => navigate("/store")} />
          </Li>
          <Li
            onMouseEnter={() => setShowservice(true)}
            onMouseLeave={() => setShowservice(false)}
          >
            <IconImage src={serviceIcon} />
            <DropdownImageMobile alt="dropdown" src={navDropdownWhite} />
            {showservice && (
              <>
                <DropdownText onClick={() => navigate("/wedding")}>
                  Sculpture wedding
                </DropdownText>
              </>
            )}
          </Li>
          <WhiteLine />
          <Li>
            <IconImage src={aboutUsIcon} onClick={() => navigate("/about")} />
          </Li>
          <Li>
            <IconImage
              src={contactUsIcon}
              onClick={() => navigate("/contact")}
            />
          </Li>
          <Li>
            <IconImage
              src={collabWithUsIcon}
              onClick={() => setShowCollab(true)}
            />
          </Li>
          <Li>
            <IconImage src={snapIcon} onClick={() => navigate("/snap")} />
          </Li>
          <Li>
            <SocialMediaContainer>
              <SocialIcon
                alt="facebook"
                src={Facebook}
                onClick={() =>
                  navigate(
                    "https://www.facebook.com/Sculpturebangkok-106077208099767"
                  )
                }
              />
              <SocialIcon
                alt="instagram"
                src={Instagram}
                onClick={() =>
                  navigate("https://www.instagram.com/sculpturebangkok/")
                }
              />
            </SocialMediaContainer>
          </Li>
        </Ul>
      </AnimatedNav>
    </>
  );
};
