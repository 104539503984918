import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { GrClose } from "react-icons/gr";
import emailjs, { init } from "emailjs-com";
import toast from "react-hot-toast";
import MoonLoader from "react-spinners/MoonLoader";
import BlackUnderline from "../../assets/wedding/blackunderline.png";
import Star from "../../assets/wedding/star.svg";

init("user_6D80ZzzIvA8VVUeLFc4nr");

export const StarImage = styled.img`
  position: absolute;
  width: 40px;
  top: -10px;
  right: -40px;
  @media only screen and (max-width: 480px) {
    width: 30px;
    top: 0px;
    right: -35px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 578px;
  height: 611px;
  margin: 37px 56px;
  @media only screen and (max-width: 480px) {
    width: 300px;
    margin: 32px 24px;
  }
`;

const Label = styled.p`
  font-size: 20px;
  width: 100%;
  margin: 0px;
  margin-bottom: 19px;
  margin-top: 15px;
  color: black;
  font-weight: 300;
`;

const Input = styled.input`
  width: 100%;
  border: 0px;
  border-bottom: 0.5px solid #7c7c7c;
  margin-bottom: 24px;
  padding: 5px 5px 5px 0px;
  font-family: "Futura";
  font-weight: 400;
  font-size: 16px;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 143px;
  border-radius: 0px;
  font-family: "Futura";
  padding: 16px 31px;
  font-weight: 400;
  font-size: 16px;
  margin-top: -8px;
  border: 0.5px solid #7c7c7c;
  @media only screen and (max-width: 480px) {
    padding: 16px 16px;
  }
`;

const SendButton = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  background-color: ${props => (props.enable ? "black" : "#C4C4C4")};
  pointer-events: ${props => (props.enable ? "auto" : "none")};
  cursor: pointer;
  :hover {
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.25);
  }
  @media only screen and (max-width: 480px) {
    height: 40px;
  }
`;

const SendButtonText = styled.p`
  font-size: 20px;
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
`;

const RequestLabel = styled.p`
  font-size: 24px;
  width: 100%;
  margin: 0px;
  margin-bottom: 24px;
  font-weight: 300;
  color: black;
  text-transform: uppercase;
  @media only screen and (max-width: 480px) {
    font-size: 20px !important;
  }
`;

const UnderlineImage = styled.img`
  position: absolute;
  width: 100%;
  bottom: -5px;
  @media only screen and (max-width: 480px) {
    width: 100% !important;
    bottom: 5px;
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.25)",
    borderRadius: "0px",
    border: "0.5px solid #000000",
    padding: "0px 0px",
    width: "fit-content",
    height: "auto"
  },
  overlay: {
    backgroundColor: "#00000080",
    zIndex: "999999"
  }
};

const HeaderBlock = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
  margin-bottom: 16px;
  @media only screen and (max-width: 480px) {
    margin-bottom: 5px;
  }
`;

export const Inquiry = ({ isOpen, closeModal }) => {
  const [requestData, setRequestData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    message: ""
  });
  const [loading, setLoading] = useState(false);

  const handleRequestClick = (data, type) => {
    setRequestData(prev => {
      let temp = prev;
      temp[type] = data;
      return { ...prev, temp };
    });
  };

  const sendRequest = () => {
    setLoading(true);
    emailjs
      .send(
        "sculpturebangkok",
        "template_7fm3ak9",
        {
          name: "Want to collaborate from " + requestData["name"],
          email: requestData["email"],
          phoneNumber: requestData["phoneNumber"],
          message: requestData["message"]
        },
        "user_6D80ZzzIvA8VVUeLFc4nr"
      )
      .then(
        result => {
          setLoading(false);
          closeModal("success");
          setRequestData({
            name: "",
            email: "",
            message: ""
          });
        },
        error => {
          toast.error(
            "Failed to send, please contant Random Sculpture directly via mobile number."
          );
          setLoading(false);
          closeModal("failed");
          setRequestData({
            name: "",
            email: "",
            message: ""
          });
        }
      );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Container>
          <GrClose
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              cursor: "pointer"
            }}
            size={16}
            onClick={() => closeModal()}
          />
          <HeaderBlock>
            <RequestLabel style={{ fontSize: "24px" }}>
              WANT TO COLLABORATE? WRITE TO US!
            </RequestLabel>
            <UnderlineImage src={BlackUnderline} alt="blackunderline" />
            <StarImage src={Star} alt="request" />
          </HeaderBlock>
          <Label>YOUR NAME*</Label>
          <Input
            placeholder={"Enter your full name"}
            value={requestData["name"]}
            onChange={e => handleRequestClick(e.target.value, "name")}
          />
          <Label>YOUR CONTACT DETAILS*</Label>
          <Input
            placeholder={"Enter your phone number"}
            value={requestData["phoneNumber"]}
            onChange={e => handleRequestClick(e.target.value, "phoneNumber")}
          />
          <Input
            placeholder={"Enter your email"}
            value={requestData["email"]}
            onChange={e => handleRequestClick(e.target.value, "email")}
          />
          <Label>LET’S COLLAB!</Label>
          <Textarea
            placeholder={"Drop your message!"}
            value={requestData["message"]}
            onChange={e => handleRequestClick(e.target.value, "message")}
          />
          <SendButton
            onClick={() => sendRequest()}
            enable={
              requestData["name"] !== "" &&
              requestData["email"] !== "" &&
              requestData["phoneNumber"] !== "" &&
              requestData["message"] !== "" &&
              !loading
            }
          >
            {loading ? (
              <MoonLoader size={20} />
            ) : (
              <SendButtonText>Send</SendButtonText>
            )}
          </SendButton>
        </Container>
      </Modal>
    </>
  );
};
