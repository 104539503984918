import React, { useState, useEffect } from "react";
import { Link, navigate } from "gatsby";
import { useSpring } from "react-spring";
import { Toaster } from "react-hot-toast";
import HorizontalLogo from "../assets/logo/sculpture_logo_no_word.png";
import NavDropdown from "../assets/icon/navdropdown.svg";
import NavDropdownWhite from "../assets/icon/navdropdownwhite.svg";
import { Footer } from "./footer";
import { Inquiry } from "./Inquiry";
import {
  Ul,
  Li,
  LogoContainer,
  LogoImage,
  DropdownBox,
  SiteWrapper,
  Nav,
  DropdownText,
  DropdownImage,
  DropdownImageMobile
} from "../components/styledComponents";
import SNAPLogo from "../assets/logo/snap.svg";
import Facebook from "../assets/landing/Facebook.svg";
import Instagram from "../assets/landing/Instagram.svg";
import LocationNavbarIcon from "../assets/landing/locationnavbaricon.png";
import StoreNavbarIcon from "../assets/landing/storenavbaricon.png";
import ServiceNavbarIcon from "../assets/landing/servicenavbaricon.png";
import LocationNavbarIconMobile from "../assets/landing/locationnavbariconmobile.png";
import StoreNavbarIconMobile from "../assets/landing/storenavbariconmobile.png";
import ServiceNavbarIconMobile from "../assets/landing/servicenavbariconmobile.png";
import AboutUsbarIconMobile from "../assets/landing/aboutusnavbariconmobile.png";
import ContactUsNavbarIconMobile from "../assets/landing/contactusnavbariconmobile.png";
import CollabWithUsNavbarIconMobile from "../assets/landing/collabwithusnavbariconmobile.png";
import {
  PageName,
  SiteHead,
  StyledLink,
  NavBurger,
  SnapFooterLogo,
  NavbarIconContainer,
  NavbarIconImage,
  NavbarIconImageMobile,
  BottomNavSpacer,
  BottomNavContainer,
  Main,
  SocialMediaContainer,
  SocialMediaWrapper,
  FooterContainerBlock,
  AllrightReserveText,
  DesignedBy,
  IconImage
} from "./LayoutComponents/components";
import { DesktopNavBurger } from "../components/LayoutComponents/DesktopNavBurger";
import { ConfirmSendRequest } from "../components/ConfirmSendRequest";
import { FailedToSendRequest } from "../components/FailedToSendRequest";
import { StoreHeaderMobile } from "./Store/Store";

const BottomNav = ({ setShowCollab, pathName }) => {
  return (
    <BottomNavContainer>
      <BottomNavSpacer />
      <Li
        className={
          pathName === "/about" ? "nav-home nav-current" : "nav-elements"
        }
        role="menuitem"
      >
        <IconImage
          src={AboutUsbarIconMobile}
          onClick={() => navigate("/about")}
        />
      </Li>
      <Li
        className={
          pathName === "/randomsculptureclub"
            ? "nav-randomsculptureclub nav-current"
            : "nav-elements"
        }
        role="menuitem"
      >
        <IconImage
          src={ContactUsNavbarIconMobile}
          onClick={() => navigate("/contact")}
        />
      </Li>
      <Li
        className={
          pathName === "/wedding" ? "nav-elements nav-current" : "nav-elements"
        }
        role="menuitem"
      >
        <IconImage
          src={CollabWithUsNavbarIconMobile}
          onClick={() => setShowCollab(true)}
        />
      </Li>
      <Li>
        <StyledLink href={"https://snapphotoservice.com/"}>
          <SnapFooterLogo src={SNAPLogo} />
        </StyledLink>
      </Li>
      <Li>
        <SocialMediaContainer>
          <SocialMediaWrapper>
            <img
              alt="facebook"
              src={Facebook}
              onClick={() =>
                navigate(
                  "https://www.facebook.com/Sculpturebangkok-106077208099767"
                )
              }
            />
          </SocialMediaWrapper>
          <SocialMediaWrapper>
            <img
              alt="instagram"
              src={Instagram}
              onClick={() =>
                navigate("https://www.instagram.com/sculpturebangkok/")
              }
            />
          </SocialMediaWrapper>
        </SocialMediaContainer>
      </Li>
      <Li>
        <FooterContainerBlock>
          <AllrightReserveText>
            © SCULPTURE BANGKOK 2021. ALL RIGHTS RESERVED.
          </AllrightReserveText>
          <DesignedBy>
            SITE DESIGNED BY PLOY PHAIRAM D. | DEVELOPMENT BY MIX CHANAWEE
          </DesignedBy>
        </FooterContainerBlock>
      </Li>
    </BottomNavContainer>
  );
};

const Layout = props => {
  const { children, siteBgColor } = props;
  const [toggleNav, setToggleNav] = useState(false);
  const [pathName, setPathName] = useState("/");
  const [showservice, setShowservice] = useState(false);
  const [showCollab, setShowCollab] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [failedIsOpen, setFailedIsOpen] = useState(false);
  const springStyles = useSpring({
    opacity: showservice ? 1 : 0,
    config: { duration: 200 }
  });

  const closeInquiryModal = status => {
    if (status) {
      setShowCollab(false);
      if (status === "success") {
        setIsOpen(true);
      } else if (status === "failed") {
        setFailedIsOpen(true);
      }
    } else {
      setShowCollab(false);
    }
  };

  const closeModal = () => {
    setIsOpen(false);
    setFailedIsOpen(false);
  };

  useEffect(() => {
    if (window) {
      setPathName(window.location.pathname);
    }
  }, []);

  return (
    <SiteWrapper
      className={`site-wrapper ${toggleNav ? `site-head-open` : ``}`}
      bgColor={siteBgColor}
      ref={props.ref}
    >
      <SiteHead disable={["/"].includes(pathName)}>
        <div className="site-head-container">
          {!["/"].includes(pathName) && (
            <LogoContainer>
              <Link to={`/`} style={{ padding: "5px" }}>
                <LogoImage src={HorizontalLogo} />
              </Link>
            </LogoContainer>
          )}
          <PageName>
            {pathName.includes("store")
              ? "STORE"
              : pathName
                  .split("/")
                  .join("")
                  .toUpperCase()}
          </PageName>
          <NavBurger
            className="nav-burger"
            href={`#`}
            onClick={() => setToggleNav(!toggleNav)}
            isIndex={pathName === "/"}
          >
            <div
              className="hamburger hamburger--collapse"
              aria-label="Menu"
              role="button"
              aria-controls="navigation"
            >
              <div className="hamburger-box">
                <div className="hamburger-inner" />
              </div>
            </div>
          </NavBurger>
          <DesktopNavBurger
            toggleNav={toggleNav}
            setToggleNav={setToggleNav}
            locationIcon={LocationNavbarIconMobile}
            storeIcon={StoreNavbarIconMobile}
            serviceIcon={ServiceNavbarIconMobile}
            aboutUsIcon={AboutUsbarIconMobile}
            contactUsIcon={ContactUsNavbarIconMobile}
            collabWithUsIcon={CollabWithUsNavbarIconMobile}
            snapIcon={SNAPLogo}
            Facebook={Facebook}
            Instagram={Instagram}
            navDropdownWhite={NavDropdownWhite}
            setShowCollab={setShowCollab}
          />
          <Nav
            id="swup"
            className="site-head-left"
            toggleNav={toggleNav}
            isIndex={["/"].includes(pathName)}
            disable={["/"].includes(pathName)}
          >
            <Ul role="menu">
              <Li
                className={
                  pathName === "/locations"
                    ? "nav-elements nav-current"
                    : "nav-elements"
                }
                role="menuitem"
              >
                <NavbarIconContainer>
                  <NavbarIconImage
                    src={LocationNavbarIcon}
                    onClick={() => navigate("/locations")}
                  />
                  <NavbarIconImageMobile
                    src={LocationNavbarIconMobile}
                    onClick={() => navigate("/locations")}
                  />
                </NavbarIconContainer>
              </Li>
              <Li
                className={
                  pathName === "/randomsculptureclub"
                    ? "nav-elements nav-current"
                    : "nav-elements"
                }
                role="menuitem"
              >
                <NavbarIconContainer>
                  <NavbarIconImage
                    src={StoreNavbarIcon}
                    onClick={() => navigate("/store")}
                  />
                  <NavbarIconImageMobile
                    src={StoreNavbarIconMobile}
                    onClick={() => navigate("/store")}
                  />
                </NavbarIconContainer>
              </Li>
              <Li
                className={
                  pathName === "/wedding"
                    ? "nav-elements nav-current"
                    : "nav-elements"
                }
                role="menuitem"
              >
                <StyledLink
                  href={`#`}
                  onMouseEnter={() => setShowservice(true)}
                  onMouseLeave={() => setShowservice(false)}
                >
                  <NavbarIconContainer>
                    <NavbarIconImage
                      src={ServiceNavbarIcon}
                      onClick={() => navigate("/wedding")}
                    />
                    <NavbarIconImageMobile
                      src={ServiceNavbarIconMobile}
                      onClick={() => navigate("/wedding")}
                    />
                  </NavbarIconContainer>
                  <DropdownImage alt="dropdown" src={NavDropdown} />
                  <DropdownImageMobile alt="dropdown" src={NavDropdownWhite} />
                  <DropdownBox showservice={showservice} style={springStyles}>
                    <DropdownText onClick={() => navigate("/wedding")}>
                      Sculpture wedding
                    </DropdownText>
                  </DropdownBox>
                </StyledLink>
              </Li>
              <BottomNav setShowCollab={setShowCollab} pathName={pathName} />
            </Ul>
          </Nav>
        </div>
        {pathName && pathName.includes("store") && <StoreHeaderMobile />}
      </SiteHead>
      <Main id="site-main" className="site-main">
        <div id="swup" className="transition-fade" style={{ height: "100%" }}>
          {children}
        </div>
      </Main>
      {["/contact", "/contact/", "/about", "/about/"].includes(pathName) && (
        <Footer />
      )}
      <Inquiry isOpen={showCollab} closeModal={closeInquiryModal} />
      <ConfirmSendRequest isOpen={isOpen} closeModal={closeModal} />
      <FailedToSendRequest isOpen={failedIsOpen} closeModal={closeModal} />
      <Toaster />
    </SiteWrapper>
  );
};

export default Layout;
